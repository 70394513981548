<template lang="pug">
#auth-form
  slot(v-bind="slotProps")
</template>

<script>
export default defineNuxtComponent({
  computed: {
    slotProps () {
      return {
        props: 'props'
      }
    }
  },
})
</script>

<style lang="scss">
#auth-form {
  .login-form {
    position: relative;
    z-index: 1;
    max-width: 500px;
    padding: 15px;
  }
  .btn-social {
    text-align: left;
    padding: 0.5rem 0 0.5rem 0.75rem;
    white-space: nowrap;
    &-icon {
      width:18px;
      height:18px;

      svg {
        display:block
      }
    }
    &-label {
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1em;
      color: $black;
    }
  }
  #login-submit {
    width: 100%;
    line-height: 1.45rem;
    .spinner-grow {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
